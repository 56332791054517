<template>
    <el-dialog title="借阅审核" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" v-loading="loading"
                 label-width="120px">
            <el-form-item label="审核状态：" prop="auditState">
                <el-radio-group v-model="inputForm.auditState" @input="auditStateChange">
                    <el-radio label="2">审核通过</el-radio>
                    <el-radio label="3">审核驳回</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="借阅时间：" prop="borrowTime" v-if="inputForm.auditState == '2'">
                <el-date-picker v-model="inputForm.borrowTime"
                                :value-format="inputForm.type == '0' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                                :format="inputForm.type == '0' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                                :type="inputForm.type == '0' ? 'datetime' : 'date'" clearable placeholder="请选择">
                </el-date-picker>
            </el-form-item>
            <el-form-item :label="inputForm.type == 0 ? '期限：' : '规划期限：'" prop="deadline" v-if="inputForm.auditState == '2'">
                <el-date-picker v-model="inputForm.deadline"
                                :value-format="inputForm.type == '0' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                                :format="inputForm.type == '0' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
                                :type="inputForm.type == '0' ? 'datetime' : 'date'" clearable placeholder="请选择">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="反馈详情：" prop="feedback" :rules="[{required: inputForm.auditState == '3' ? true : false, message: '反馈详情不能为空', trigger: 'change'}]">
                <el-input
                        maxlength="200"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容（限200字）"
                        v-model.trim="inputForm.feedback">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            var validateBorrowTime = (rule, value, callback) => {
                let {auditState, deadline, type} = this.inputForm
                let date = new Date()
                if(auditState == '2') {
                    if (type == 2 || type == 3) {
                        date = new Date().setHours(0, 0, 0, 0)
                    } else {
                        date = new Date()
                    }
                    if (new Date(value) < date) {
                        callback(new Error('借阅时间必须大于或等于当前时间'));
                        return
                    }
                    if (deadline && new Date(value) > new Date(deadline)) {
                        callback(new Error('借阅时间必须小于或等于期限'));
                        return;
                    }
                    if (new Date(deadline) > date && new Date(deadline) >= new Date(value)) {
                        this.$refs['inputForm'].clearValidate(['deadline']);
                    }
                    callback();
                }
            };
            var validateDeadline = (rule, value, callback) => {
                let {auditState, borrowTime, type} = this.inputForm
                let date = new Date()
                if(auditState == '2') {
                    if (type == 2 || type == 3) {
                        date = new Date().setHours(0, 0, 0, 0)
                    } else {
                        date = new Date()
                    }
                    if (new Date(value) < date) {
                        callback(new Error(type == 0 ? '期限必须大于或等于当前时间' : '规划期限必须大于或等于当前时间'));
                        return
                    }
                    if (borrowTime && new Date(value) < new Date(borrowTime)) {
                        callback(new Error(type == 0 ? '期限必须大于或等于借阅时间' : '规划期限必须大于或等于借阅时间'));
                        return;
                    }
                    if (new Date(borrowTime) > date && new Date(borrowTime) <= new Date(value)) {
                        this.$refs['inputForm'].clearValidate(['borrowTime']);
                    }
                    callback();
                }
            };
            return {
                visible: false,
                loading: false,
                inputForm: {
                    ids: [],
                    auditState: '',
                    borrowTime: '',
                    deadline: '',
                    feedback: '',
                    menuName: this.$route.meta.title || '',
                    type: '',
                },
                dataRule: {
                    auditState: [
                        {required: true, message: '审核状态不能为空', trigger: 'change'}
                    ],
                    borrowTime: [
                        {required: true, message: '借阅时间不能为空', trigger: 'change'},
                        {validator: validateBorrowTime, trigger: 'blur'}
                    ],
                    deadline: [
                        {required: true, message: '期限不能为空', trigger: 'change'},
                        {validator: validateDeadline, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            // type 0电子，1阅览，2借出，3内部利用
            init(ids, row, type) {
                console.log(ids, row);
                this.loading = false
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    this.inputForm.ids = ids
                    this.inputForm.type = type
                    if (ids.length == 1) {
                        this.inputForm.borrowTime = row.borrowTime
                        this.inputForm.deadline = row.deadline
                    } else {
                        this.inputForm.borrowTime = ''
                        this.inputForm.deadline = ''
                    }
                })
            },
            // 审核状态
            auditStateChange() {
                this.$refs['inputForm'].clearValidate(['feedback']);
            },
            // 确定
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let p = JSON.parse(JSON.stringify(this.inputForm))
                        let api = this.api.record.updateAudit
                        if (this.inputForm.type != 0) {
                            api = this.api.record.entityAudit
                            p.borrowTime = p.borrowTime ? p.borrowTime + ' 00:00:00' : ''
                            p.deadline = p.borrowTime ? p.deadline + ' 00:00:00': ''
                        }
                        this.$axios(api, p, 'put').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                if (this.inputForm.auditState == 2) {
                                    this.$message.success('通过成功')
                                } else {
                                    this.$message.success('驳回成功')
                                }
                            } else {
                                if (this.inputForm.auditState == 2) {
                                    this.$message.error('通过失败')
                                } else {
                                    this.$message.error('驳回失败')
                                }
                            }
                            this.$emit('refreshDataList', 'close')
                            this.visible = false
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
